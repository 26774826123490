<template>
	<base-blur :blur="!!modal.status" v-if="modal.form">
		<div class="product-review-form-wrapper">
			<div class="product-review-form-image-wrapper">
				<header class="image-header">
					{{ modal.product.name }}
				</header>
				<div class="image-wrapper">
					<cms-image class="product-review-form-image" :src="modal.product.thumbnail"
						:base="{width: 310,height: 260}"
						:sm="{width: 350,height: 300}"
						:md="{width: 400,height: 350}"
						:lg="{height: 300}"
						:xl="{height: 310}"
						:xxl="{height: 330}"/>
				</div>
			</div>
			<form class="product-review-form" v-if="modal.form">
				<div class="product-review-form-top">
					<input-template wider :input="modal.form.inputs.author" />
					<input-template wider :input="modal.form.inputs.email" />
				</div>
				<input-template wider :input="modal.form.inputs.rate" />
				<input-template class="product-review-form-textarea" wider
								:input="modal.form.inputs.content" />
				<span class="counter" :class="{ 'counter-red': currentContentLength >= maxContentLength }">{{
						currentContentLength
					}}/{{maxContentLength}}</span>
				<button-submit type="white" class="product-review-form-button" @click="modal.submit()" :loading="modal.processing" :rightSlot="true">
					{{ langs.submitButton }}
				</button-submit>
			</form>
		</div>
		<template #status>
			<div class="status" >
				<base-font size="md" family="secondary" color="primary">
					{{ langs.status[modal.status] || langs.status.error}}
				</base-font>
			</div>
		</template>
	</base-blur>
</template>

<script>
import { InputTemplate } from '@f/core/forms/inputs'
import ButtonSubmit from '~/website/front/components/molecules/ButtonSubmit/ButtonSubmit'
import BaseBlur from '~/website/front/components/atoms/BaseBlur/BaseBlur'

export default {
	props: {
		modal: Object
	},
	components: {
		BaseBlur,
		InputTemplate,
		ButtonSubmit
	},
	computed: {
		statusTranslation () {
			return this.$app.translator.get('forms.productReviewForm.status')
		},
		langs () {
			return this.$app.translator.get('forms.productReviewForm')
		},
		currentContentLength () {
			let currentLength
			try {
				currentLength = this.modal.form.inputs.content.value.length
			} catch (e) {
				currentLength = 0
			}
			return currentLength
		}
	},
	data: () => ({
		maxContentLength: 450
	}),
	methods: {
		async addNewReview () {
		}
	}
}
</script>

<style lang="scss" scoped>
.product-review-form-wrapper {
	height: auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@include media-breakpoint-up(xl) {
		flex-direction: row;
	}

	.product-review-form-image-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media-breakpoint-up(xl) {
			padding-right: 1.5em;
		}

		.image-header {
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 500;
			font-family: $primary-font;
			padding-bottom: 1em;
		}
		.product-review-form-image {
			margin-top: 1em;
		}
	}

	.product-review-form {
		display: flex;
		flex-direction: column;
		width: 100%;

		& :deep(.field-label) {
			label {
				color: $primary;
				font-size: 14px;
			}
		}

		& :deep(.field-wrapper .error-wrapper) {
			height: 0;
		}

		& :deep(.product-review-form-textarea) {
			padding-bottom: 0;
			.textarea {
				resize: none;
			}
		}

		.product-review-form-button {
			align-self: flex-end;
			justify-content: center;
			margin: 1em 0;

			:deep(button) {
				margin: 0;

				@include media-breakpoint-up(lg) {
					width: 270px;
				}
			}
		}
		.product-review-form-top {
			display: flex;
			flex-direction: column;

			@include media-breakpoint-up(sm) {
				flex-direction: row;
			}

			@include media-breakpoint-up(xl) {
				flex-direction: row;
			}
		}
	}
}
.counter {
	text-align: right;

	&-red {
		color: $danger-600;
	}
}
</style>
