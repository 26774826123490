import { Seo } from '~/cms/front/core/seo/Seo'

class SingleProductData extends Seo {
	get reviewList () {
		return this.page.product.reviews.list.map(review => {
			return {
				'@type': 'Review',
				reviewRating: {
					'@type': 'Rating',
					ratingValue: review?.rate,
					bestRating: '5'
				},
				author: {
					'@type': 'Person',
					name: review?.author
				},
				reviewBody: review?.content
			}
		})
	}

	get openGraph () {
		const openGraph = super.openGraph
		const image = openGraph.find(og => og.property === 'og:image')
		image.content = `${this.app.settings.env.origin}/api/images/${this.page.product.thumbnail.path}`
		return openGraph
	}

	get price () {
		return (this.page.product.price / 100).toFixed(2)
	}

	get schemaJSON () {
		const string = this.page.value.description.replace(/(<([^>]+)>)/ig, '').slice(0, 120)

		const schema = {
			'@context': 'https://schema.org/',
			'@type': 'Product',
			name: this.page.product.name,
			image: [
				`${this.app.settings.env.origin}/api/images/${this.page.product.thumbnail.path}`
			],
			description: string,
			brand: {
				'@type': 'Brand',
				name: this.app.settings.general.contact.pageName
			},
			review: this.reviewList,
			offers: {
				'@type': 'Offer',
				url: `${this.app.settings.env.origin}${this.page.route.fullPath}`,
				priceCurrency: 'PLN',
				price: this.price,
				availability: 'https://schema.org/InStock'
			}
		}

		if (this.page.product.numberOfReviews) {
			schema.aggregateRating = {
				'@type': 'AggregateRating',
				ratingValue: this.page.product.avgRate,
				bestRating: '5',
				reviewCount: this.page.product.numberOfReviews
			}
		}

		return schema
	}
}

export { SingleProductData }
