import { Modal } from '@f/core/Modal'
import NewProductReviewModalComponent from './NewProductReviewModal.vue'
import { Form } from '@f/core/forms/Form'
import { InputEmail, InputText, Textarea, InputRate } from '@f/core/forms/inputs'
import { email, required, max } from 'utils/inputs/validation-front'

class NewProductReviewModal extends Modal {
	component = NewProductReviewModalComponent
	title = ''
	processing = false
	form = false
	product
	status = false

	constructor (product) {
		super(...arguments)
		this.product = product
	}

	open () {
		super.open()
		this.createForm()
	}

	async createForm () {
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('forms.productReviewForm')

		this.title = this.app.translator.get('forms.productReviewForm.title')

		this.form = new Form()
		this.form.addInput(
			InputText.create({
				translations: () =>
					this.app.translator.get('forms.productReviewForm.inputName'),
				attribute: 'author',
				name: 'author',
				validation: [required()]
			})
		)
		this.form.addInput(InputEmail.create({
			translations: () => this.app.translator.get('forms.productReviewForm.inputEmail'),
			attribute: 'email',
			name: 'email',
			validation: [email()]
		}))
		this.form.addInput(InputRate.create({
			translations: () => this.app.translator.get('forms.productReviewForm.inputRate'),
			attribute: 'rate',
			name: 'rate',
			validation: [required()]
		}))
		this.form.addInput(Textarea.create({
			translations: () => this.app.translator.get('forms.productReviewForm.inputTextarea'),
			attribute: 'content',
			name: 'content',
			maxLength: 450,
			validation: [required(), max(450, 'Wprowadzona opinia jest zbyt długa!')]
		}))
	}

	async submit () {
		if (!await this.form.validate()) return false
		this.processing = true
		const status = await this.app.getService('rext').createReview({
			productId: this.product.id,
			...this.form.getValues()
		})
		if (!status.error) this.app._emit('product:review:created')
		this.status = status
	}
}

export { NewProductReviewModal }
