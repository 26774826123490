<template>
	<div class="product-content">
		<div class='opinions-top'>
			<base-font class="title" variant="section-header" tag="h2">
				{{langs.opinions_heading}}
			</base-font>
			<base-button class="opinion-add-button" :rightSlot="true" type="white" @click="openNewOpinionModal">
				{{langs.add_opinion}}
			</base-button>
		</div>
		<div v-if="reviews.list.length" class="opinions-wrapper">
			<div class="opinion" v-for="item in reviews.list" :key="item.id">
				<div class="opinion-top">
					<base-font
						class="opinion-author"
						variant="paragraph"
						tag="h4"
						weight="bold"
						uppercase>
						{{item.author | surnameShortener}}</base-font>
					<div class="opinion-rating">
						<base-svg name="star" class="star" v-for="el in 5" :key="el" :class="{'disabled-star': el> Math.max(item.rate)}" />
					</div>
				</div>
				<base-font variant="paragraph" tag="p" weight="bold">Pisze:</base-font>
				<div class="opinion-description" v-html="item.content"></div>
			</div>
		</div>
		<div v-else class="no-opinion-wrapper">
			<base-font variant="medium-header" tag="h3" weight="bold">
				{{langs.no_opinions}}
			</base-font>
		</div>
		<div class="load-more-button-wrapper">
			<base-button class="load-more-button" v-if="areMoreReviewsToShow" type="white" @click="loadMoreReviews">Więcej opinii</base-button>
		</div>
	</div>
</template>

<script>
import { NewProductReviewModal } from '../../components/NewProductReviewModal/NewProductReviewModal.js'
import BaseButton from '~/website/front/components/atoms/BaseButton/BaseButton'

export default {
	components: { BaseButton },
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		langs () {
			return this.$app.translator.get('products')
		},
		reviews () {
			return this.$app.page.product.reviews
		},
		areMoreReviewsToShow () {
			return this.reviews.count > this.reviews.list.length
		}
	},
	filters: {
		surnameShortener (value) {
			const splittedName = value.split(' ')
			if (!splittedName[1]) return value
			splittedName[1] = splittedName[1].substr(0, 1).toUpperCase() + '.'
			return splittedName.join(' ')
		}
	},
	methods: {
		async loadMoreReviews () {
			await this.$app.page.loadMoreReviews()
		},
		openNewOpinionModal () {
			NewProductReviewModal.createAndOpen(this.$app.page.product)
		}
	}
}
</script>
<style lang="scss" scoped>
	.opinions-top{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.btn{
			justify-content: center;
			margin: 0 0 4rem 0;
		}
		@include media-breakpoint-up(md){
			flex-direction: row;
			justify-content: space-between;
			align-items: baseline;
		}
	}
	.opinions-wrapper {
		display: flex;
		flex-wrap: wrap;
		column-gap: 10px;
		align-items: center;
		margin-bottom: 40px;

		@include media-breakpoint-up(lg){
			flex-direction: row;
		}
		.opinion {
			width: 100%;
			padding: 4rem 3rem;
			background: $white;
			box-shadow: $secondary-shadow;
			margin-bottom: 2rem;

			@include media-breakpoint-up(lg){
				width: calc((100% - 3rem)/2);
				padding: 2rem 1.5rem;
				margin-bottom: 1rem;
				min-height: 27rem;
			}
			@include media-breakpoint-up(xxl){
				width: calc((100% - 6rem)/3);
				height: 23rem;
			}

			&-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				position: relative;
				z-index: 1;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					width: 100%;
					border-bottom: .1rem solid $gray-500;
					z-index: 0;
				}
			}
			:deep(.opinion-author), :deep(.opinion-rating) {
				z-index: 1;
				position: relative;
				background: $white;
				padding-right: 1rem;
			}
			&-rating {
				display: flex;
				align-items: center;
				padding: 0 0 0 1rem;
				.star {
					width: 1.5rem;
					height: 1.5rem;
					fill: $gold-300;
					&.disabled-star{
						fill: $gray-500
					}
				}
			}
			&-description{
				font-style: italic;
				overflow: hidden;
			}
		}
	}
	.load-more-button-wrapper {
		display: flex;
		width: 100%;
		justify-content: center;

		@include media-breakpoint-up(xl){
			display: flex;
			justify-content: center;
		}

		.load-more-button {
			justify-content: center;
		}

	}
	.no-opinion-wrapper {
		margin-bottom: 4rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 8rem;
		}
	}
</style>
