import { Page } from '~/cms/front/core/pages/Page'
import { SingleProductData } from '~/seo/front/core/structuredData/SingleProduct'
import component from './Product.vue'
import { MetaPixelService } from '~/website/front/core/services/MetaPixelService'

class ProductPage extends Page {
	static alias = 'product'
	component = component
	product = {}

	constructor ({
		app,
		route,
		from
	}) {
		super({
			app,
			route,
			from
		})
		this.seo = new SingleProductData(app, { page: this })
	}

	async init () {
		await super.init()
		setTimeout(() => {
			this.emitViewContent()
		}, 200)

		if (this.app.authorization.isAuthorizated) {
			const service = await this.app.getService('rext')
			if (this.app.authorization.user === undefined) return
			await service.klaviyoCreateProductPageEvent(
				{
					email: this.app.authorization.client.user.email,
					productName: this.product.name,
					productCategory: this.product.category.name,
					productValue: this.product.price
				}
			)
		}
	}

	emitViewContent () {
		if (process.client) {
			const facebookPixelData = {
				page_title: document.title,
				event_url: location.href,
				content_type: 'product',
				post_id: this.product.id,
				content_name: this.product.name,
				category_id: this.product.category.id,
				category_name: this.product.category.name,
				value: this.product.price / 100,
				currency: 'PLN',
				price_before_discount: this.product.priceBeforeDiscount ? this.product.priceBeforeDiscount / 100 : 'unknown',
				contents_id: [this.product.id],
				user_role: this.app.authorization.isAuthorizated ? 'client' : 'guest',
				contents: [{
					id: this.product.id,
					name: this.product.name
				}],
				content_ids: [this.product.id]
			}
			MetaPixelService.emitEvent('ViewContent', facebookPixelData)
		}
	}

	async fetchData () {
		const Product = this.app.getEntity('product')
		this.product = await Product.fetchOne(this.alias)
		if (!this.product) return this.app.vue.$redirect({ path: '/404' })
		await this.product.loadReviews(3)
		await this.setPageData(this.product.page)
	}

	get header () {
		return {
			title: this.value.header ? this.value.header : this.product.name
		}
	}

	loadMoreReviews () {
		return this.product.loadMoreReviews(3)
	}

	restore () {
		super.restore()
		const Product = this.app.getEntity('product')
		this.product = Product.create(window.__PAGE__.product)
		this.value = window.__PAGE__.product.page.value
	}

	store () {
		const store = super.store()
		delete store.value
		store.product = this.product
		return store
	}

	afterRedirect () {
		this.app._emit('product:details', this.product)
	}
}

export { ProductPage }
