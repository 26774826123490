<template>
	<article class="product">
		<base-container>
			<base-row>
				<base-col lg="5">
					<product-slider class="product-slider" :images="value.gallery" />
				</base-col>
				<base-col col="12" lg="7">
					<div class="product-description">
						<div class="product-description-header">
							<base-font class='product-name' tag="h1" variant="medium-header" weight="regular">
								{{product.name}}
							</base-font>
						</div>
						<div class="product-description-text" v-html="value.description"></div>
						<div class="opinion" v-if="product.numberOfReviews">
							<div class="opinion-rating">
								<base-font class="opinion-value" marginReset  weight="medium">{{avgRounded}}/5</base-font>
								<base-svg name="star" class="star" v-for="el in 5" :key="el" :class="{'disabled-star': el> Math.max(avgRounded)}" />
							</div>
							<base-font class="opinion-text" variant="paragraph" weight="medium">{{baseOn}}</base-font>
						</div>
						<div class="product-bottom">
							<product-button @click="addToCart">
								{{langs.add_to_cart_extended}}
							</product-button>
							<field-quantity @is-focused="setFieldFocus" v-model="quantity" />
							<div class="product-price-wrapper">
								<base-font v-if="product.isDiscount" class="product-discount" variant="price"><base-price :price="product.priceBeforeDiscount"/></base-font>
								<base-font class="product-price" variant="price"><base-price :price="product.price"/></base-font>
							</div>
						</div>
					</div>
				</base-col>
			</base-row>
			<cms-multi-section class="row" value="content"/>
			<product-opinions :value="value" />
			<product-button class="add-to-card-btn" @click="product.addToCart(quantity)">
				{{langs.add_to_cart_extended}}
			</product-button>
			<div v-if="product.recommendedProducts">
				<base-font variant="section-header" tag="h2">
					{{value.recommendedProductsHeader || 'Rekomendowane produkty'}}
				</base-font>
				<slim-products-list :products="product.recommendedProducts"/>
			</div>
		</base-container>
	</article>
</template>

<script>
import CmsMultiSection from '~/cms/front/components/CmsMultiSection'
import FieldQuantity from '~/shop/front/components/FieldQuantity/FieldQuantity.vue'
import ProductButton from '~/shop/front/components/ProductButton/ProductButton.vue'
import ProductSlider from '~/shop/front/components/ProductSlider/ProductSlider.vue'
import SlimProductsList from '~/shop/front/components/SlimProductsList/SlimProductsList.vue'
import ProductOpinions from '~/shop/front/sections/ProductOpinions/ProductOpinions'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	name: 'Product',
	components: {
		CmsMultiSection,
		FieldQuantity,
		ProductSlider,
		ProductOpinions,
		ProductButton,
		SlimProductsList
	},
	data: () => ({
		quantity: 1
	}),
	computed: {
		product () {
			return this.$app.page.product
		},
		value () {
			return this.$app.page.value
		},
		langs () {
			return this.$app.translator.get('products')
		},
		baseOn () {
			return this.langs?.base_on_x_reviews?.replace('<<reviewsLength>>', this.product.numberOfReviews)
		},
		avgRounded () {
			return this.product.avgRate.toFixed(2)
		}
	},
	methods: {
		setFieldFocus (state) {
			this.isFocus = state
		},

		addToCart () {
			this.product.addToCart(this.quantity)
		}
	},
	async prefetch () {
		await this.$app.translator.prefetch('products')
	},
	mixins: [
		pageMixin
	]
}
</script>
<style lang="scss" scoped>
.row {
	display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
	margin-top: 8rem;
    margin-right: -1.5rem;
    margin-left: -1.5rem;
}

.opinion {
	display: flex;
	flex-direction: column;
	margin-top: 2.5rem;

	@include media-breakpoint-up(xl) {
		margin-bottom: 2rem;
		flex-grow: 1;
		justify-content: end;
	}

	&-rating {
		display: flex;
		align-items: center;
		margin-bottom: - .5rem;
	}
	& :deep(.opinion-text ) {
		font-size: 1.2rem;
		margin-top: 3px;
		line-height: 1rem;
	}
	.star {
		width: 1.5rem;
		height: 1.5rem;
		fill: $gold-300;
		&:first-of-type {
			margin-left: .5rem;
		}
		&.disabled-star{
			fill: $gray-500
		}
	}
}

.product {
	:deep(.product-name) {
		position: relative;
		padding-bottom: 1rem;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0.5rem;
			height: .1rem;
			width: 60%;
			background: $gray-500;

			@include media-breakpoint-up (lg) {
				bottom: -1rem;
			}
		}
	}
	&-slider {
		width: 100%;
	}
	&-description {
		padding: 3rem;
		background-color: $white;
		box-shadow: $secondary-shadow;
		overflow: hidden;
		height: 100%;
		display: flex;
		flex-direction: column;

		&-text {
			@include media-breakpoint-up (xl) {
				padding-right: 11rem;
			}
		}
		& :first-child {
			margin-top: 0;
		}
		&-header {
			display: flex;

			.capacity-badge-wrapper {
				right: 21px;
				top: -17px;
				position: absolute;

				@include media-breakpoint-up(xl) {
					right: 10px;
					top: 50px;
				}

				:deep(.badge) {
					padding: 0 4rem 0 2.5rem;
				}
			}
		}
	}
	.field-quantity {
		position: absolute;
		bottom: 10rem;

		@include media-breakpoint-up (xl) {
			margin-left: 2rem;
			position: initial;
			bottom: auto;
			margin-bottom: .5rem;
			margin-right: auto;
			}
	}
	:deep(.field-quantity-select), :deep(.field-quantity-input) {
			height: 5rem;
			border: .1rem solid $gray-500;
			text-align-last:center;
		}
	&-bottom {
		display: flex;
		flex-direction: column-reverse;

		@include media-breakpoint-up (xl) {
			margin-bottom: 0;
			flex-direction: row;
			align-items: flex-end;

			.btn-product {
				flex-grow: .3;
			}
		}
	}
	.product-price-wrapper {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		margin-bottom: 2rem;

		@include media-breakpoint-up(xl) {
			margin-bottom: 0;
		}
	}
	& :deep(.product-discount) {
		position: relative;
		color: $white;
		z-index: 1;
		margin-top: 3rem;

		@include media-breakpoint-up (xl) {
			margin-bottom: 2rem;
			margin-top: 0;
		}

		&::before {
			content: '';
			position: absolute;
			background: $gold-600;
			top: 50%;
			right: -5rem;
			transform: skew(13deg) translateY(-50%);
			z-index: -1;
			width: 14.5rem;
			height: 3.4rem;

			@include media-breakpoint-up (xl) {
				height: 4.4rem;
				left: -3rem;
				width: 16rem;
				right: auto;
			}
		}
		&::after {
			content: '';
			position: absolute;
			top:50%;
			left: 50%;
			width: 8rem;
			height: .1rem;
			background: $white;
			transform: translate(-50%, -50%) rotate(-15deg);
		}
	}
	& :deep(.product-price) {
		flex-grow: 4;
		font-size: 5rem;
		text-align: right;
		margin-top: 2rem;

		@include media-breakpoint-up (xl) {
			margin-top: 0;
			margin-bottom: 1.5rem;
		}
	}
	& .add-to-card-btn {
		margin: auto;
		width: 100%;
		margin-bottom: 4rem;

		@include media-breakpoint-up(lg) {
			width: 236px;
		}
	}
}
</style>
