<template>
	<nav class="slider-pagination">
		<ul class="pagination-list">
			<li
				v-for="(item, i) in length"
				:key="i"
				@click.stop="$emit('activeIndexChanged', i)"
				:class="{
					active: i === activeImageIndex
				}"
				class="pagination-item"
			></li>
		</ul>
	</nav>
</template>
<script>
export default {
	props: {
		length: Number,
		activeImageIndex: Number
	}
}
</script>
<style lang="scss">
.slider-pagination {
	z-index: 1;
	@include media-breakpoint-up(sm) {
		margin-bottom: -10px;
	}
	.pagination-list {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	.pagination-item {
		background-color: rgba(#3A3738, .3);
		width: 10px;
		height: 5px;
		margin-right: 1rem;
		cursor: pointer;
		@include transition;
		&:last-child {
			margin-right: 0;
		}
		&.active {
			width: 30px;
			background-color: #000;
		}
	}
}
</style>
