<template>
	<div class="product-slider-modal" ref="modal" @click.stop="close">
		<div class="slider-button-wrapper slide-left-btn">
			<div class="slide-left-btn transparent-btn" @click="prevImage">
				<base-svg class="slider-btn-icon btn-left" name="arrow_left" />
			</div>
		</div>
		<div class="slider">
			<div class="close">
				<slot/>
			</div>
			<div class="thumbnails">
				<figure
					class="thumbnail"
					:class="{active: i === index}"
					v-for="(image, i) in images"
					:key="image.path"
					@click="goTo(i)"
				>
					<cms-image
						class="slide-product-img"
						:src="image.image"
						:base="{height: 45, width: 45}"
						:md="{height: 60, width: 60}"
					/>
				</figure>
			</div>
			<cms-image
				class="slide-product-img landscape-image"
				position=""
				:src="activeImageSrc"
				:base="{height: 1080, width: 1080}"
			/>
		</div>
		<div class="slider-button-wrapper slide-right-btn ">
			<div class="slide-right-btn transparent-btn" @click="nextImage">
				<base-svg class="slider-btn-icon btn-left" name="arrow_right" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			index: this.activeImageIndex
		}
	},
	props: {
		images: Array,
		activeImageIndex: Number
	},
	computed: {
		activeImageSrc () {
			return this.images[this.index].image
		}
	},
	methods: {
		nextImage () {
			this.goTo(this.index + 1)
		},
		prevImage () {
			this.goTo(this.index - 1)
		},
		goTo (index) {
			this.index = (index + this.images.length) % this.images.length
		},
		close (e) {
			if (e.target === this.$refs.modal) this.$emit('closeModal')
		}
	}
}
</script>

<style lang="scss" scoped>
.slider {
	position: relative;
	background-color: white;
	.thumbnails {
		display: flex;
		position: absolute;
		bottom: -50px;
		left: 0;
		@media screen and (orientation:landscape) {
			display: block;
			top: 0;
			bottom: inherit;
			left: -48px;
		}
		@include media-breakpoint-up(md) {
			bottom: -70px;
		}
		@include media-breakpoint-up(xl) {
			left: -65px;
		}
	}
	.thumbnail {
		position: relative;
		cursor: pointer;
		margin: 0;
		height: 45px;
		overflow: hidden;
		margin-right: 3px;
		margin-bottom: 3px;
		@include media-breakpoint-up(md) {
			height: 60px;
			margin-bottom: 5px;
			&::before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba($black, 0.35);
				opacity: 0;
				@include transition;
			}
			&.active {
				&::before {
					opacity: 1;
				}
			}
		}
		@include media-breakpoint-up(md) {
			&:hover {
				&::before {
					opacity: 1;
				}
			}
		}
	}
	.close {
		position: absolute;
		top: 20px;
		right: 20px;
	}
}
.product-slider-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: $level2;
	background-color: rgba($black, 0.35);
	backdrop-filter: blur(4px);

	:deep(.landscape-image img) {
		width: auto;
		height: auto;
		max-height: calc(100vmin - 30px);
		max-width: calc(100vmin - 30px);
		@media screen and (max-height: map-get($grid-breakpoints, sm)) and (orientation: landscape){
			height: 100vh;
		}
	}

	.slider-button-wrapper {
		margin: 2rem 0;
		z-index: $level1;
		position: relative;

	}
	.slide-left-btn {
		left: 50px;

		@include media-breakpoint-up(xl) {
			left: 0;
			margin-right: 30px;
		}
	}
	.slide-right-btn {
		right: 50px;

		@include media-breakpoint-up(xl) {
			right: 0;
			margin-left: 30px;
		}
	}
	.transparent-btn {
		background: transparent;
		border: none;
		cursor: pointer;
		transition: 0.5s;

		@include media-breakpoint-up(xl) {
			&:hover {
				transition: 0.5s;
				transform: scale(1.2);
			}
		}

		.slider-btn-icon {
			width: 2em;
		}
	}
}
</style>
