<template>
	<div class="slider-positional-container">
		<div class="product-slider-wrapper">
			<div class="capacity" v-if="product.capacity">
				<base-svg class="capacity-icon" name="capacity_background" />
				<base-font class="capacity-value">{{ capacity }}</base-font>
			</div>
			<div class="slider" :class="{'isModal': isModal}">
				<div class="slider-button-wrapper">
					<div class="slide-left-btn transparent-btn" @click="prevImage">
						<base-svg class="slider-btn-icon btn-left" name="arrow_left" />
					</div>
				</div>
				<div class="slider-image-wrapper" @click="toggle" ref="imageWrapper">
					<cms-image
						class="slide-product-img"
						:src="activeImageSrc"
						:base="{height: 250}"
						:md="{height: 470}"
						:lg="{height: 300}"
						:xl="{height: 350}"
						:xxl="{height: 380}"
					/>
				</div>
				<div class="slider-button-wrapper">
					<div class="slide-right-btn transparent-btn" @click="nextImage">
						<base-svg class="slider-btn-icon btn-left" name="arrow_right" />
					</div>
				</div>
			</div>
			<product-slider-pagination
				class="product-slider-pagination"
				@activeIndexChanged="goTo"
				:activeImageIndex="activeImageIndex"
				:length="images.length"
			/>
		</div>
		<product-slider-modal v-if="isModalOpen" :images="images" :activeImageIndex="activeImageIndex" @closeModal="toggle">
			<button-close @close="toggle"/>
		</product-slider-modal>
	</div>
</template>

<script>
import ButtonClose from '~/website/front/components/molecules/ButtonClose'
import ProductSliderPagination from './ProductSliderPagination'
import ProductSliderModal from '../ProductSliderModal/ProductSliderModal'
import BaseSvg from '@f/components/BaseSvg/BaseSvg'

export default {
	name: 'ProductSlider',
	components: {
		ProductSliderPagination,
		ProductSliderModal,
		ButtonClose,
		BaseSvg
	},
	props: {
		images: Array,
		isModal: Boolean
	},
	computed: {
		activeImageSrc () {
			return this.images[this.activeImageIndex].image
		},
		product () {
			return this.$app.page.product
		},
		capacity () {
			return this.product.capacity.value + this.product.capacity.unit
		}
	},
	data: () => ({
		activeImageIndex: 0,
		isModalOpen: false
	}),
	methods: {
		nextImage () {
			this.goTo(this.activeImageIndex + 1)
		},
		prevImage () {
			this.goTo(this.activeImageIndex - 1)
		},
		goTo (index) {
			this.slideAnimation()
			this.activeImageIndex = (index + this.images.length) % this.images.length
		},
		slideAnimation () {
			this.$refs.imageWrapper.animate([
				{ opacity: 0 }, { opacity: 1 }
			], 1000)
		},
		toggle () {
			this.isModalOpen = !this.isModalOpen
		}
	}

}
</script>
<style lang="scss" scoped>
.slider.isModal {
	max-width: 400px;
    justify-content: center;
    flex-wrap: wrap;
	.slider-button-wrapper {
		order: 1;
		margin: 2rem 0;
	}
	@include media-breakpoint-up(md) {
		max-width: none;
		justify-content: baseline;
		flex-wrap: nowrap;
		.slider-button-wrapper {
			order: initial;
			margin: 0;
		}
	}

}
.slider-positional-container {
	height: 100%;
	display: flex;
	.product-slider-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: inherit;
		align-self: center;

		@include media-breakpoint-up(xs) {
			margin-bottom: 25px;
		}
		@include media-breakpoint-up(sm) {
			margin-bottom: 25px;
		}

		.capacity {
			position: absolute;
			top: 20px;
			right: 30px;
			z-index: 2;

			&-icon {
				width: 50px;
				height: 50px;
			}
			:deep(.capacity-value) {
				position: relative;
				top: -40px;
				left: 0;
				text-align: center;
				font-weight: 500;
			}
		}

		.slider {
			height: 100%;
			display: flex;
			align-items: center;

			&-button-wrapper {
				z-index: 1;
				.slide-left-btn {
					margin-right: 15px;
				}
				.slide-right-btn {
					margin-left: 15px;
				}

				.transparent-btn {
					background: transparent;
					border: none;
					cursor: pointer;
					transition: 0.5s;

					&:hover {
						transition: 0.5s;
						transform: scale(1.2);
					}

					.slider-btn-icon {
						width: 1em;
					}
				}
			}

			&-image-wrapper {
				width: fit-content;
				height: inherit;
				margin: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;
				@include media-breakpoint-up(xl) {
					cursor: pointer;
				}
			}
		}

		.product-slider-pagination {
			align-self: center;
			margin-top: 2rem;
		}
	}
}

</style>
